<template>
  <div>
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic0A95E2A1-37FD-4EA4-8CFA-7F5217A393F6.jpg -->
    <img class="img-large rounded" src="">

    <p>
      <b>Brickworld</b><br>
      Chicago, USA<br>
      June 18-21, 2009
    </p>
    <p>
      The LEGO Universe team is excited to be at Brickworld 2009! This awesome fan event, which was created by Adult Fans of LEGO (AFOLs), offers loads of fun for everyone: building, competitions, movies, games and some of the coolest creations ever!
    </p>
    <p>
      <i><b>FREE GIFT!</b></i><br>
      If you come to the event you might even get a free gift! Enter the code <b>LEGOFAN</b> into the VIP area on the site and print out the coupon. If you are one of the first 200 fans to bring it to the LEGO Universe table you will get a free LEGO goody!
    </p>
    <p>
      Hope to see you there!
    </p>
  </div>
</template>
